import React, { useState, useMemo, Fragment } from 'react'
import { DashboardFull, DashboardReportRow, DashboardQuery, RowType, DashboardRow, DashboardKPI, LibraryReport, LoginSession } from '../../types/transfertypes';
import { Order } from '../../types/enums';
import { parseDashboardData } from '../../helpers/TypeHelpers';
import ShowIf from '../Generic/ShowIf';
import { getKpiTotalsNew } from '../../helpers/DashboardHelpers';
import { formatValue } from '../../helpers/ReportHelpers';
// @ts-ignore
import { Link } from 'react-router-dom'
import ReactTooltip from 'react-tooltip';
import moment from 'moment';

import '../../css/DashboardAdmin.css'
import HideIf from '../Generic/HideIf';

interface DashboardTableProps {
    user?: LoginSession,
    dashboard: DashboardFull,
    setHoverValues: (rowId: string, kpiKey: string) => void,
    reports: LibraryReport[],
    hoverRow: string,
    hoverKpi: string,
    style?: React.CSSProperties
}

const DashboardTable2 = (props: DashboardTableProps) => {

    const [sortingKpiKey, setSortingKpiKey] = useState("")
    const [sortDirection, setSortDirection] = useState<Order>(Order.ASC)

    const parsedDashboard = useMemo(() => parseDashboardData(props.dashboard.dashboard_data), [props.dashboard])
    const total = useMemo(() => getKpiTotalsNew(props.dashboard.table_data, parsedDashboard.kpis, parsedDashboard.main_row), [props.dashboard, parsedDashboard])

    const rows = useMemo(() => {
        let tmp = parsedDashboard.rows.filter(r => !r.hidden)
        if (sortingKpiKey != "") {
            tmp = tmp.sort((a, b) => {
                if (sortingKpiKey == "row-name") {
                    let aName: string
                    let bName: string
                    if (a.row.label != "") {
                        aName = a.row.label
                    } else {
                        let r = props.reports.find(v => v.report.report_id == (a.row as DashboardReportRow).report_id)
                        aName = r?.report.name ?? "Undefined" 
                    }
                    if (b.row.label != "") {
                        bName = b.row.label
                    } else {
                        let r = props.reports.find(v => v.report.report_id == (b.row as DashboardReportRow).report_id)
                        bName = r?.report.name ?? "Undefined" 
                    }
                    return sortDirection == Order.ASC ? aName.localeCompare(bName) : bName.localeCompare(aName)
                }

                //@ts-ignore
                let aData = props.dashboard.table_data[sortingKpiKey][a.row_id]
                //@ts-ignore
                let bData = props.dashboard.table_data[sortingKpiKey][b.row_id]
                let aNum = aData != null ? aData.last : sortDirection == Order.ASC ? Infinity : -Infinity
                let bNum = bData != null ? bData.last : sortDirection == Order.ASC ? Infinity : -Infinity
                return sortDirection == Order.ASC ?  aNum - bNum : bNum - aNum

            })
        }
        return tmp

    }, [parsedDashboard, sortingKpiKey, sortDirection])

    const getDisplayValue = (value: number, kpi: DashboardKPI, kpiNumber: number, diff: any) => {
        let tmpValue = kpi.percent ? value * 100 / total[kpiNumber] : value
        let tmp = formatValue(true, tmpValue, kpi.decimal)
     
        if (kpi.percent && !kpi.show_trend) {
            return `${tmp}%`
        }  
        if (kpi.show_trend ) {
            if (kpi.trend == 0) {
                if (diff > 0) {
                    return <span >  {kpi.percent ? tmp`%` :tmp}  <img className="" style={{marginLeft:"5px"}} alt="" src={` ${ 'img/PolygonNeutralDown.svg' }`}></img></span>
                } else if (diff < 0 ){
                    return <span >  {kpi.percent ? tmp`%` :tmp}  <img className="" style={{marginLeft:"5px"}} alt="" src={` ${ 'img/PolygonNeutralUp.svg' }`}></img></span>
                } else {
                    return <span >  {kpi.percent ? tmp`%` :tmp}  <img className="" style={{marginLeft:"5px"}} alt="" src={` ${ 'img/PolygonNeutral.svg' }`}></img></span>
                }
            } else if(kpi.trend == 1){
                if (diff > 0) {
                    return <span > {kpi.percent ? tmp`%` :tmp } <img className="" style={{marginLeft:"5px"}} alt="" src={` ${ 'img/PolygonGreenUp.svg' }`}></img></span>
                } else if (diff < 0) {
                    return <span > {kpi.percent ? tmp`%` :tmp } <img className="" style={{marginLeft:"5px"}} alt="" src={` ${ 'img/PolygonRedDown.svg' }`}></img></span>
                } else {
                    return <span >  {kpi.percent ? tmp`%` :tmp}  <img className="" style={{marginLeft:"5px"}} alt="" src={` ${ 'img/PolygonNeutral.svg' }`}></img></span>
                }
            
            } else if (kpi.trend == -1) {
                if (diff < 0) {
                    return <span > {kpi.percent ? tmp`%` :tmp } <img className="" style={{marginLeft:"5px"}} alt="" src={` ${ 'img/PolygonGreenDown.svg' }`}></img></span>
                } else if (diff > 0)  {
                    return <span > {kpi.percent ? tmp`%` :tmp } <img className="" style={{marginLeft:"5px"}} alt="" src={` ${ 'img/PolygonRedUp.svg' }`}></img></span>
                } else {
                    return <span >  {kpi.percent ? tmp`%` :tmp}  <img className="" style={{marginLeft:"5px"}} alt="" src={` ${ 'img/PolygonNeutral.svg' }`}></img></span>
                }
            }
        }
        return tmp
    }

    const clickSort = (kpiKey: string) => {
        if (sortingKpiKey != kpiKey) {
            setSortingKpiKey(kpiKey)
            setSortDirection(Order.ASC)
        } else {
            setSortDirection(v => v == Order.ASC ? Order.DESC : Order.ASC)
        }
    }

    const getTableRow = (r: DashboardRow, key: any, classes: string = '', mainRow: DashboardRow | undefined ) => {
        let reportRow: DashboardReportRow | undefined
        let filterRow: DashboardQuery | undefined
        let report: LibraryReport | undefined


        if (r.type == RowType.Report) {
            reportRow = r.row as DashboardReportRow
            report = props.reports.find(v => v.report.report_id == reportRow?.report_id)
            if (report == undefined) return <Fragment key={key} ></Fragment>
        } else {
            filterRow = r.row as DashboardQuery
        }
        return <tr key={key} className={classes}>
            <td>
                <ShowIf if={r.type == RowType.Report}>
                    <Link to={`/report/${r.row_id}`} className="cancel-a-style">
                        <ShowIf if={report?.report.is_public ?? false}>
                            <i className="fa fa-file fa-fw report-icon" aria-hidden="true" title='Public Insight'></i>
                        </ShowIf>
                        <ShowIf if={!report?.report.is_public && props.user != undefined}>
                            {
                                report?.report.user_id === props.user?.user_id ?
                                    <i className="fa fa-unlock-alt fa-fw report-icon" aria-hidden="true" title='Your private Insight'></i>
                                    :
                                    <i className="fa fa-lock fa-fw report-icon" aria-hidden="true" title='Other persons private Insight'></i>
                            }
                        </ShowIf>
                        {reportRow && (reportRow.label != "") ? reportRow.label : (report?.report.name ?? "Undefined")}
                    </Link>
                </ShowIf>
                <ShowIf if={r.type == RowType.Filter}>
                    <Link to={`/report/${mainRow ? mainRow.row_id : ""}/overview?dId=${props.dashboard.dashboard_data.id}&rowId=${r.row_id}&table=1`} className="cancel-a-style">
                        <i className="fa fa-filter fa-fw report-icon" aria-hidden="true" title='Filter'></i>
                        {filterRow?.label ?? "Undefined"}
                    </Link>
                </ShowIf>
            </td>
            {
                parsedDashboard.kpis.map((kpi, i) => {
                    //@ts-ignore
                    const data = props.dashboard.table_data[kpi.kpi_key]?.[r.row_id]
                    const hover = props.hoverRow == r.row_id && props.hoverKpi == kpi.kpi_key
                    const differencePrecedingPlus = data?.difference != undefined && data.difference > 0 ? "+" : ""
                    const targets = r.targets[kpi.kpi_key] ?? []
                    const targetVals: {value: number, bad: boolean, label: string}[] = new Array(targets.length).fill(0).map(() => {return {bad: false, value: 0, label: "(N/A)"}})
                    let badTarget = false
                    if (data != null) {
                        for(let i = 0; i < targets.length; i++){
                            const target = targets[i]
                            if (!target.enabled) continue
                            let value = target.value
                            if (target.is_kpi_target) {
                                let tmpData = props.dashboard.table_data[target.kpi_key]?.[target.row_id]
                                if (tmpData == null) continue
                                value = tmpData.last
                            }
                            targetVals[i].value = value
                            if (target.trend > 0) {
                                if (data.last < value){
                                    badTarget = true
                                    targetVals[i].bad = true
                                    targetVals[i].label = "(not reached)"
                                } else {
                                    targetVals[i].label = "(reached)"
                                }
                            } else if (target.trend < 0) {
                                if (data.last > value) {
                                    badTarget = true
                                    targetVals[i].bad = true
                                    targetVals[i].label = "(not reached)"
                                } else {
                                    targetVals[i].label = "(reached)"
                                }
                            } else {
                                if (data.last > value) {
                                    targetVals[i].label = "(over)"
                                } else if (data.last < value) {
                                    targetVals[i].label = "(under)"
                                } else {
                                    targetVals[i].label = "(on target)"
                                }
                            }
                        }
                    }
                    return (
                    <Fragment key={i}>
                        <td className={`kpi-td text-align-right ${hover ? 'dashboard-table-hover' : ''} ${badTarget ? 'target-warning' : ''}`} 
                            onMouseEnter={() => props.setHoverValues(r.row_id, kpi.kpi_key)}
                            onMouseLeave={() => props.setHoverValues("", "")}>
                            <span data-tip data-for={`tooltip-${r.row_id}-${kpi.kpi_key}`}>
                                {data != null ? getDisplayValue(data.last, kpi, i, data.difference) : "N/A"}
                            </span>
                                {
                                    data != null ?
                                        <ReactTooltip id={`tooltip-${r.row_id}-${kpi.kpi_key}`} type="dark" effect='solid' place='bottom' className="dashboard-table-cell-info opaque">
                                            <p className="text-align-center">
                                                <i className={`fa fa-long-arrow-${data.difference > 0 ? 'up' : 'down'} margin-right-5px`}></i>
                                                Dev. last {moment.unix(data.last_data_time).diff(moment.unix(data.first_data_time), "days")} days
                                                <i className={`fa fa-long-arrow-${data.difference > 0 ? 'up' : 'down'} margin-left-5px`}></i>
                                            </p>
                                            <p className="height-10px">
                                                <span className="float-left">
                                                    Last month
                                                </span>
                                                <span className="float-right">
                                                    {data.has_error && data.first === 0 ? "N/A" : formatValue(true, data.first, kpi.decimal)}
                                                </span>
                                            </p>
                                            <p className="height-10px">
                                                <span className="float-left">
                                                    Today
                                                </span>
                                                <span className="float-right">
                                                    {data.has_error && data.last === 0 ? "N/A" : formatValue(true, data.last, kpi.decimal)}
                                                </span>
                                            </p>
                                            <hr className="margin-bottom-5px" />
                                            <span className={`dashboard-table-cell-info-main-number `}>
                                                <span className="float-left">
                                                    <b>{differencePrecedingPlus + formatValue(true, data.difference_percent, true)}%</b>
                                                </span>
                                                <span className="float-right">
                                                    <b>{differencePrecedingPlus + formatValue(true, data.difference, kpi.decimal)}</b>
                                                </span>
                                            </span>
                                            <ShowIf if={targets.length != 0 && targets.some(v => v.enabled)}>
                                                <br className="mb-2"/>
                                                <p className="text-align-center mt-2">
                                                    <i className={`fa fa-dot-circle-o margin-right-5px`}></i>
                                                    Target{targets.length == 1 ? "" : "s"}
                                                    <i className={`fa fa-dot-circle-o margin-left-5px`}></i>
                                                </p>
                                                {
                                                    targets.map((t, i) => {
                                                        if (!t.enabled) return <Fragment key={i}></Fragment>
                                                        if (t.trend == 0) {
                                                            
                                                        }
                                                        return <p key={i} className="height-10px">
                                                            <span className="float-left"><b>{formatValue(true, targetVals[i].value, true)}</b></span>
                                                            <span className="float-right"><i>{targetVals[i].label}</i></span>
                                                        </p>
                                                    })
                                                }

                                            </ShowIf>
                                        </ReactTooltip> : null }
                        </td>
                    </Fragment>
                    )

                })
            }
        </tr>
    }

    let style = props.style ?? {}
    return <>
        <table className="kpi-table" style={style}>
            <thead className="'kpi-thead">
                <tr className='kpi-tr'>
                    <th className='kpi-th abc-click text-align-right' onClick={() => clickSort("row-name")} >
                        <ShowIf if={sortingKpiKey == "row-name"}>
                            <span className={`fa ${sortDirection == Order.ASC ? "fa-arrow-down" : "fa-arrow-up"} abc-sort-icon`} />
                        </ShowIf>
                    </th>
                    {
                        parsedDashboard.kpis.map((kpi, i) => {
                            return <th className='kpi-th text-align-right abc-click' key={i} onClick={() => clickSort(kpi.kpi_key)}>
                                {kpi.label != "" ? kpi.label : kpi.column}
                                <ShowIf if={sortingKpiKey == kpi.kpi_key}>
                                    <span className={`fa ${sortDirection == Order.ASC ? "fa-arrow-down" : "fa-arrow-up"} abc-sort-icon`} />
                                </ShowIf>
                            </th>
                        })
                    }
                </tr>
            </thead>
            <tbody className='kpi-tbody'>
                <HideIf if={parsedDashboard.main_row.hidden}>
                    {getTableRow(parsedDashboard.main_row, "main", "bold", undefined)}
                </HideIf>
                {
                    rows.map((v, i) => {
                        return getTableRow(v, i, "", parsedDashboard.main_row)
                    })
                }
                
            </tbody>
        </table>
    </>
}

export default DashboardTable2
