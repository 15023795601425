/* REACT */
import React from 'react'
import { connect } from 'react-redux'
import { Line } from 'react-chartjs-2'
import { formatValue } from '../../helpers/ReportHelpers'
import { getArrowValues, bigWidgetOptions } from '../../helpers/DashboardHelpers'
import moment from 'moment'


const mapStateToProps = (state, ownProps) => {
    return {

    }
}
export class BigWidget extends React.Component {
    getHighlightedColor(id, key) {
        const { hoverReportId, hoverKpiKey } = this.props
        if (id == hoverReportId && hoverKpiKey === key) return 'dashboard-background-selected'

        return 'dashboard-background'
    }

    render() {
        // const { indexJ } = this.props
        
        const widget = this.props.data
        if (!widget) return null
        
        const dataPoints = widget.data_points ? widget.data_points : []
        let options = bigWidgetOptions(widget.last_data_time, dataPoints)

        let labels = []
        dataPoints.forEach((item) => {
            labels.push(item + "")
        })
        const noData = widget.data_points === null ? true : false

        const column = widget.column
        const name = widget.report_name
        const label = widget.label && widget.label !== "" ? widget.label : ""

        let bodyCss = getComputedStyle(document.body);
        // let targetLine = bodyCss.getPropertyValue("--tangerine");
        let lineColor = bodyCss.getPropertyValue("--dark-forest");
        let avg = 0;
        dataPoints.forEach(i => {
            avg += i
        })
        const avg_sum = avg/dataPoints.length        

        const lastDate = moment.unix(widget.last_data_time).format('MMMM YYYY')
        let months = []
        months.push(lastDate)
        for (let i = 1; i < 12; i++) {
            var prevMonthName  = moment().subtract(i, "month").format('MMMM YYYY');
            months.push(prevMonthName)
        }
        months = months.reverse()


        const data = (canvas) => {
            let datasets = []
            datasets.push({
                label: "datapoints",
                data: dataPoints,
                fill: false,
                borderColor: lineColor,
                borderWidth: 2  ,
                pointRadius: (ctx) => {
                    const pointLenght = ctx.chart.data.labels.length - 1
                    const pointArray = []
                    for (let i=0; i <= pointLenght; i++){
                        pointArray.push(2)
                    } 
                    return pointArray
                },
                clip: false,
                pointHitRadius: 0,
                pointBackgroundColor: (ctx) => {
                const pointLenght = ctx.chart.data.labels.length - 1
                const pointArray = []
                for (let i=0; i <= pointLenght-1; i++){
                    if (i == pointLenght -1 ) {
                        pointArray.push(lineColor)
                    }
                    else {
                        pointArray.push(lineColor)
                    }
                } 
                pointArray.push(lineColor)
                return pointArray
                },
                pointBorderWidth: 0,       
                pointBorderColor: (ctx) => {
                    const pointLenght = ctx.chart.data.labels.length - 1
                    const pointArray = []
                    for (let i=0; i <= pointLenght-1; i++){
                        if (i == pointLenght -1 ) {
                            pointArray.push(lineColor)
                        }
                        else {
                            pointArray.push(lineColor)
                        }
                    } 
                    pointArray.push(lineColor)
                    return pointArray
                },
            })

            if (widget.found_target) {
                
                datasets.push({
                    label: "target",
                    data: dataPoints.map(r => widget.target),
                    fill: true,
                    borderColor: targetLineColor,
                    borderWidth: 2,
                    pointRadius: 0,
                    pointHitRadius: 0,
                    borderDash: [5,5],
                    backgroundColor: underTargetLineColor,
                    tooltip: false,
                    intersect: true,
                    hoverRadius: 0,
                })
            } else {
                    datasets.push({
                    label: "avg",
                    data:  dataPoints.map(r => avg_sum),
                    fill: true,
                    borderColor: targetLineColor,
                    borderWidth: 2,
                    pointRadius: 0,
                    pointHitRadius: 0,
                    borderDash: [5,5],
                    backgroundColor: underTargetLineColor,
                    tooltip: false,
                    hoverRadius: 0,
                    intersect: true                    
                })
            }
            datasets.push({
                label: "dates",
                data: months,
            })

            return {
                labels: labels,
                datasets: datasets,
            }
        }
         
        const plugins = {
            id:"plugin",
            afterUpdate: (chart) => {
                var datasetX = chart.config.data.datasets[0];
                var xOffset = 6;

                for (var i = 0; i < datasetX.data.length; i++) {
                    if (i == datasetX.data.length -1) {
                        for (var key in datasetX._meta) {
                            var model = datasetX._meta[key].data[i]._model;
                            model.x -= xOffset;
                            model.controlPointNextX -= xOffset;
                            model.controlPointPreviousX -= xOffset;
                       }
                    }
                    if (i == 0) {
                        for (var j in datasetX._meta) {
                            var model1 = datasetX._meta[j].data[i]._model;
                            model1.x += 3;
                            model1.controlPointNextX += 3;
                            model1.controlPointPreviousX += 3;
                       }
                    }
                }        
            },
        }
       
        const lastVal = widget.last_value || widget.last_value === 0 ? widget.last_value : -1
        const trendObj = getArrowValues(dataPoints[dataPoints.length - 2], lastVal, widget.trend)

        let underTargetLineColor = "#F8F3E7" //neutral
        let targetLineColor = "#D0D5C5" //neutral

        if (widget.found_target && (widget.last_value || widget.last_value === 0)) {
            if (widget.target_trend === -1 && widget.target >= widget.last_value){ //good
                underTargetLineColor = "#E8EAD4" 
                targetLineColor = "#63AD50"
            } else if (widget.target_trend === -1 && widget.target < widget.last_value){ //bad
                underTargetLineColor = "#F8DCCC" 
                targetLineColor = "#FF5656"
            } else if (widget.target_trend === 1 && widget.target > widget.last_value) { //bad
                underTargetLineColor = "#F8DCCC"
                targetLineColor = "#FF5656"
            } else if (widget.target_trend === 1 && widget.target <= widget.last_value) { //good
                underTargetLineColor = "#E8EAD4" 
                targetLineColor = "#63AD50"
            }
        }
        
        // const targetColor = getTargetColor(widget.percent ? widget.percent_value : lastVal, widget.target, widget.target_trend, widget.found_target)
        let formattedVal
        if (widget.percent) {
            formattedVal = (widget.last_value || widget.last_value === 0) ? formatValue(true, widget.percent_value, widget.decimal) + "%" : "N/A"
        } else {
            formattedVal = (widget.last_value || widget.last_value === 0) ? formatValue(true, lastVal, widget.decimal) : "N/A"
        }
        if (noData) formattedVal = "N/A"

        return (
            <div
                className={`${this.props.fromSecret ? "dashboard-large-secret" : "dashboard-large"}  inline-block pos-rel ${widget.is_report ? 'abc-click' : ''} ${underTargetLineColor=="#E8EAD4" ? "widget-color-positive" : underTargetLineColor=="#F8DCCC" ? "widget-color-negative" : "widget-color-neutral"}
                ${!this.props.home ? this.getHighlightedColor(widget.row_id != '' ? widget.row_id : widget.report_id, widget.kpi_key) : "dashboard-background"}
                ${lastVal < 0 ? (underTargetLineColor=="#E8EAD4" ? "widget-color-positive-filler" : underTargetLineColor=="#F8DCCC" ? "widget-color-negative-filler" : "wat") : "watwat"}
                    `}
                // onMouseEnter={!this.props.fromHome ? () => this.props.setHoverValues(widget.row_id, widget.kpi_key) : null}
                // onMouseLeave={!this.props.fromHome ? () => this.props.setHoverValues("", "") : null}
                title={widget.is_report ? "Go to Insight" : ""}
                // data-tip
                // data-for={`tooltip-${widget.row_id}-${widget.kpi_key}-${indexJ}-${widget.type}`}
            >
                {/* <WidgetToolTip index={indexJ} value={formattedVal} widget={widget} direction={trendObj.direction} differencePrecedingPlus={""} /> */}
                <div className="height-100px container ">
            
                    <div style={{display:'flex'}}>
                    <div className="height-14px " style={{color:"#455955", marginTop:"4px", fontSize:"12px"}}>{label !== "" ? `${label}` : `${column} - ${name}`}</div>
                    </div>
                    <div className="row text-align-center height-60px" style={{marginTop:"5px", textAlign:"center", alignItems:"center"}}>
                        <div className="col-12 kpi-wrapper">
                            <div style={{marginRight:"5px", minWidth:"84px", float:"right"}}>
                            {
                                !noData ?
                                    <span className="h1-fix " >
                                        {formattedVal}
                                    </span>
                                    : <span className="h1-fix">N/A</span>
                            }

                            </div>
                            <div className='vertical-line'></div>

                            <div className='widget-percentage-info ' style={{fontSize:"18px", minWidth:"84px"}}> 
                            <div style={{display:"flex", verticalAlign:"middle"}}>
                            <span style={{ marginLeft:"1px", fontStretch:"expanded"}} >{formatValue(true, widget.difference_percent, true)}%</span>

                            {!noData && widget.trend !== 0 ? 
                                    <img className="" style={{marginLeft:"5px"}} alt="" src={` ${trendObj.isPositive ? widget.difference_percent > 0 ? 'img/PolygonGreenUp.svg' : widget.difference_percent === 0 ? 'img/PolygonNeutral.svg': 'img/PolygonGreenDown.svg' : widget.difference_percent > 0 ? 'img/PolygonRedUp.svg' : widget.difference_percent === 0 ? 'img/PolygonNeutral.svg': 'img/PolygonRedDown.svg'  }`}></img>
                                    : !noData && widget.trend === 0 ?
                                    <img className="" style={{marginLeft:"5px"}} alt="" src={` ${ widget.difference_percent > 0 ? 'img/PolygonNeutralUp.svg' : widget.difference_percent < 0 ? 'img/PolygonNeutralDown.svg' : 'img/PolygonNeutral.svg' }`}></img>
                                        : null    
                            }
                            </div>
                            <div className="" style={{fontSize:"10px", color:"#304642"}}> Since last month</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="widget-container z-index-1" >
                    <Line
                        height={52}
                        redraw={true}
                        data={data}
                        options={options}
                        plugins={[plugins]}
                    />
                    
                </div>
                    <span style={{marginRight:"5px", float:"right", fontSize:"10px"}}>
                        <span className={`${widget.found_target ? "fa fa-dot-circle-o" : "" }`} style={{marginRight:"2px", marginTop:"2px"}}> </span>
                        {widget.found_target && widget.target_trend === -1 ? "max." : widget.found_target && widget.target_trend === 1 ? "min." : widget.found_target ? "" : "avg."} {widget.found_target ? formatValue(true, widget.target, widget.decimal) : formatValue(true, avg_sum)}{widget.found_target && widget.percent ? "%" : ""}
                    </span>
            </div>
        )
    }
}

BigWidget = connect(mapStateToProps)(BigWidget)

export default BigWidget
