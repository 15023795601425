/* REACT */
import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { getDownloadInfo, requestDownloadFile } from '../actions/DownloadActions'
import { downloadReportGridData, getGroups } from '../actions/ReportActions'
import { getBucketsDataInfo } from '../actions/BucketActions'
import { getUserInfo } from '../actions/UserActions'
import BgColor from '../components/Generic/BgColor'
import ShowIf from '../components/Generic/ShowIf'
import { getUsers } from '../actions/SystemActions'
import { Link } from 'react-router-dom'
import { getFilter } from '../helpers/ReportHelpers'
import { notifySuccess } from '../helpers/NotificationManager'
import DataSize from '../components/Generic/DataSize'
import { getAddress } from '../helpers/ApiHelpers'
import DataSpecs from './DataSpecs'
import Select from '../components/Generic/CustomSelect'
import { is, canUseDownload } from '../helpers/PermissionHelpers'



import '../css/DataSpecs.css'
import GetDownloadLinkDialog from '../components/Dialogs/GetDownloadLinkDialog'
import RequirePermission from '../components/RequirePermission'

const mapStateToProps = (state, ownProps) => {
    return {
        buckets: state.Bucket.bucketDetails,
        userInfo: state.User.info,
        downloadInfo: state.Download.info,
        users: state.System.users,
        groups: state.Report.groups,
    }
}

class Download extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            tab: 0,
            selectedBucketId: null,
            showGetLinkDialog: false,
            downloadInfoFetched: false
        }
    }

    componentDidMount() {
        let { dispatch } = this.props
        dispatch(getUserInfo())
        dispatch(getGroups())
        dispatch(getBucketsDataInfo())
        if(!this.props.users)
            dispatch(getUsers())
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { dispatch } = this.props
        if (prevProps.userInfo && this.props.userInfo && this.props.userInfo.access && canUseDownload(this.props.userInfo.access) && !this.state.downloadInfoFetched) {
            dispatch(getDownloadInfo())
            this.setState({downloadInfoFetched: true})
        }
    }

    downloadReportData = report => {
        const data = {
            name: report.name,
            type: 'csv',
            query: {
                sort_column: report.sort_column,
                sort_direction: report.sort_direction,
                filter: getFilter(report, []),
                column_filter: JSON.parse(report.columns),
                offset: 0, 
                limit: report.limit,
            },
            include_comments: false,
        }

        notifySuccess("You will get a notification when your download is ready")

        this.props.dispatch(downloadReportGridData(report.bucket, data))
    }

    downloadFile = file => {
        const { downloadInfo} = this.props
        if(!downloadInfo) return;

        notifySuccess("You will get a notification when your download is ready")
        const bucketIds = Object.keys(downloadInfo.bucket_download_info).sort((a, b) => downloadInfo.bucket_download_info[a].bucket_name > downloadInfo.bucket_download_info[b].bucket_name ? 1 : -1)
        const selectedBucketId = this.state.selectedBucketId ?? (bucketIds.length > 0 ? bucketIds[0] : "")
        this.props.dispatch(requestDownloadFile(selectedBucketId, file.name))
    }
    onCloseGetLinkDialog = () => {
        this.setState({showGetLinkDialog: false})
    }
    render() {
        const { downloadInfo, groups, users, buckets } = this.props
        
        if(!buckets) return null 

        const reportGroups = groups?.data?.groups ?? []
        reportGroups.push({group_id: 0, name: "Private"})

        const bucketIds = buckets.keys().sort((a, b) => buckets.get(a).info.name > buckets.get(b).info.name ? 1 : -1)
        const selectedBucketId = this.state.selectedBucketId ?? (bucketIds.length > 0 ? bucketIds[0] : "")
        const selectedBucket = downloadInfo ? downloadInfo.bucket_download_info[selectedBucketId] : undefined
        const opts = new Set()
        bucketIds.forEach((id) => {
            opts.add(id)
        })
        const actualOptions = Array.from(opts).map((o) => {
            return {value: o, label: buckets.get(o).info.name }
        })
        const staticLink = selectedBucket ? getAddress(`download/${selectedBucket.static_link_token}`) : ""
        return (
            <BgColor bgClass="background">
                 <div className="container-fluid">
                    <h1 className="mb-0 pt-3">Data</h1>
                    <hr className="full-width-hr hr-color margin-top-5px"/>
                    <div className="width-100p ">
                        <RequirePermission perms={is.downloadUser}>
                        <div className="compose-navigation ">
                            <div className={`compose-tab ${this.state.tab === 0 ? "active-compose-tab" : ""}`} onClick={() => this.setState({ tab: 0 })}><span>Data Specifications</span></div>
                            <div className={`compose-tab ${this.state.tab === 1 ? "active-compose-tab" : ""}`} onClick={() => this.setState({ tab: 1 })}><span> Download</span></div>
                        </div>
                        </RequirePermission>
                        <div className="bg-white width-100p height-100-p padding-10px">
                            <ShowIf if={!selectedBucketId}>
                                <p className="alert alert-warning">
                                    It seems like you don't have access to any buckets. If you believe this is an error, please contact <a href="mailto:support@inact.io">support@inact.io</a>.
                                </p>
                            </ShowIf>   
                            <ShowIf if={selectedBucketId && this.state.tab === 0 }>
                            <DataSpecs 
                                bucketId={selectedBucketId}
                            />
                            </ShowIf>                                                
                            <ShowIf if={this.state.tab === 1 }>
                                <div className="dataspecs-filters " style={{marginTop:"10px"}} >

                                    <div  style={{whiteSpace: "nowrap", display: "flex", alignItems:"center", marginLeft:"10px"}}>
                                    <span className="vertical-align-middle" style={{marginRight: 8}}>{`Data Source: `}</span>
                                    </div>
                                    <Select options={Object.values(actualOptions)} placeholder={selectedBucket ? selectedBucket.bucket_name :""} className={"fixed-slicer-select margin-left-10"} onChange={e => this.setState({selectedBucketId: e["value"]})} classNamePrefix="fixed-slicer-select"   />

                                    <div style={{whiteSpace: "nowrap", display: "flex", alignItems:"center", marginLeft:"20px"}}>
                                    {selectedBucket ? `Last updated: ${moment.unix(selectedBucket.last_update).fromNow()}` : ""}
                                    </div>

                                    <ShowIf if={selectedBucket}>
                                        <span className='dataspecs-toolbar inline-flex no-margin '>
                                        <button className=" btn btn-default btn-sm abc-click" onClick={() => this.setState({showGetLinkDialog: true})} > Get Link </button>
                                        </span>
                                        <GetDownloadLinkDialog 
                                            show={this.state.showGetLinkDialog} 
                                            staticLink={staticLink} 
                                            onClose={this.onCloseGetLinkDialog}>    
                                        </GetDownloadLinkDialog>
                                    </ShowIf>
                                </div>
                            </ShowIf>
                            
                            <ShowIf if={selectedBucket && this.state.tab === 1 && (!selectedBucket.downloadable_files || selectedBucket.downloadable_files.length === 0)}>
                                <i>You currently have no files available for download.</i>
                            </ShowIf>
                            
                            <ShowIf if={selectedBucket && this.state.tab === 1 && selectedBucket.downloadable_files && selectedBucket.downloadable_files.length > 0}> 
                                
                                <h4 style={{marginLeft:"10px", marginTop:"20px"}}> Files </h4>

                                <div style={{paddingLeft:"10px", paddingRight:"10px", paddingBottom:"10px"}}>

                                <table className=" table table-condensed table-bordered table-hover table-striped margin-top-10px">
                                    <thead>
                                        <tr>
                                            <th>File name</th>
                                            <th>Size</th>
                                            <th>Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            !selectedBucket || !selectedBucket.downloadable_files ? null : selectedBucket.downloadable_files.map(f => {
                                                return (
                                                    <tr key={f.name}>
                                                        <td>{f.pretty_name}</td>
                                                        <td><DataSize size={f.size} /></td>
                                                        <td>
                                                            <i className="fa fa-cloud-download icon-gray abc-click" title="Download file" onClick={() => this.downloadFile(f)}/>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                                </div>
                                <h4 style={{marginLeft:"10px", marginTop:"20px"}}> Insights </h4>

                                <div style={{paddingLeft:"10px", paddingRight:"10px", paddingBottom:"10px"}}>

                                <table className="table table-condensed table-bordered table-hover table-striped margin-top-10px">
                                        <thead >
                                        <tr>
                                            <th className="width-30"></th>
                                            <th>Insight name</th>
                                            <th>Group</th>
                                            <th>Author</th>
                                            <th>Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            !selectedBucket || !selectedBucket.reports ? null : selectedBucket.reports.map(r => {
                                                const groupName = reportGroups.find(g => g.group_id === r.group_id)?.name ?? r.group_id
                                                const author = users?.find(u => u.id === r.user_id)
                                                const authorName = author ? `${author.firstname} ${author.lastname}` : r.user_id
                                                return (
                                                    <tr key={r.report_id}>
                                                        <td className="width-30 text-align-center">
                                                            <Link to={`report/${r.report_id}`} className="cancel-a-style">
                                                                <i className="fa fa-external-link icon-gray"/>
                                                            </Link>
                                                        </td>
                                                        <td>{r.name}</td>
                                                        <td>{groupName}</td>
                                                        <td>{authorName}</td>
                                                        <td>
                                                            <i className="fa fa-cloud-download icon-gray abc-click" title="Download report data" onClick={() => this.downloadReportData(r)}/>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                                </div>
                            </ShowIf>
                            
                        </div>
                    </div>
                </div>
            </BgColor>
        )
    }
}
        
Download = connect(mapStateToProps)(Download)

export default Download